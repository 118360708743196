import React from 'react'
import {Link} from 'react-router-dom'

class NotFoundPage extends React.Component {
  render(){
    return(
      <>
        <h1>404 Not Found</h1>
        <Link to="/">Home</Link>
        <Link to="/thankyou">Thankyou</Link>
        <Link to="/projects/the-great-escape">The Great Escape</Link>
      </>
    )
  }
}

export default NotFoundPage