import React from 'react'
import ContactForm from './ContactForm'
import ContactDetails from './ContactDetails'

class GetInTouch extends React.Component {
  render(){
    return(
      <section className='_section _section-contact-us _light_background' id="contact">
        <div className="_grid">
          <div className="_column">
            <div className="_panel">
              <h2 className="_heading-1">GET IN TOUCH</h2>
              <p>We look forward to the opportunity to work with you and help your organisation achieve its goals.<br />Please don't hesitate to <strong>contact us</strong> for more information or to schedule a consultation.</p>
              <ContactForm />
            </div>
          </div>
        </div>
        
      </section>
    )
  }
}

export default GetInTouch