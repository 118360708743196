import React from 'react'
import savings from '../assets/img/vat-savings-review@2x.jpg'
import risk from '../assets/img/vat-risk-review@2x.jpg'
import compliance from '../assets/img/vat-compliance-services@2x.jpg'

class ThirdSection extends React.Component {
  render(){
    return(        
      <section className='_section _light_background'>
        <div className='_grid'>
          <div className='_column _padding-bottom-100'>
            <div className='_panel'>
              <h2 className='_heading-1'>OUR SERVICES</h2>
              <p className='_margin-bottom-0'>We offer a wide range of services, tailored to meet the unique needs of your company.</p>
              
            </div>
          </div>
        </div>
        <div className='_grid _grid-3 _our-services'>
          <div className='_column'>
            <img src={savings} alt="VAT SAVINGS REVIEW" />
            <h2 className='_heading-2'>VAT<br /><span className='_orange'>SAVINGS REVIEW</span></h2>
            <p>Targeted at the recovery of VAT overpayments or unclaimed VAT benefits.</p>
          </div>
          <div className='_column'>
            <img src={risk} alt="VAT RISK REVIEW" />
            <h2 className='_heading-2'>VAT<br /><span className='_orange'>RISK REVIEW</span></h2>
            <p>To detect and prevent any risks in the treatment of VAT.</p>
          </div>
          <div className='_column'>
            <img src={compliance} alt="VAT COMPLIANCE SERVICES" />
            <h2 className='_heading-2'>VAT<br /><span className='_orange'>COMPLIANCE SERVICES</span></h2>
            <p>Supporting clients with outsourcing VAT submissions to SARS.</p>
          </div>
        </div>
      </section>        
    )
  }
}

export default ThirdSection