import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {Navigate} from 'react-router-dom'

const ContactForm = props => {

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setInputs({
      ['non_profit_organization']: 'yes',
      ['vat_registered']: 'yes'
    })
  },[])

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const Errors = () => {

    if(!errors)
    return

    if(typeof errors === 'string')
    return <div className='_error'>{errors}</div>
    
    return Object.values(errors).map((error, index) => {
      return <div key={index} className='_error'>{error}</div>
    })
  }

  const handleSubmit = e => {
    e.preventDefault();

    const apiPath = 'https://admin.itaxsol.stageoptimal.co.za/api/contact'  
    // const apiPath = 'http://127.0.0.1:8000/api/contact'

    axios.post(apiPath, inputs)
      .then(function (response) {
        setSuccess(true)
        console.log('message sent')
      })
      .catch(function (error) {
        setErrors(error.response.data)
      })
      .finally(function () {
        // always executed
      });
  }

  if(success){
    return <Navigate to="/thankyou" />
  }
  
  return(
    <form className='_contact-form' onSubmit={handleSubmit}>
      <div className='_input-row'>
        
        <div className='_input-group'>
          <label>Company Name</label>
          <input 
            className='_input-element'
            type="text" 
            name="company_name" 
            value={inputs.company_name || ""} 
            onChange={handleChange}
          />
        </div>
        <div className='_input-group'>
          <label>Name & Surname</label>
          <input 
            className='_input-element'
            type="text" 
            name="fullname" 
            value={inputs.fullname || ""} 
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className='_input-row'>
        <div className='_input-group'>
          <label>Email Address</label>
          <input 
            className='_input-element'
            type="email" 
            name="email" 
            value={inputs.email || ""} 
            onChange={handleChange}
            required
          />
        </div>

        <div className='_input-group'>
          <label>Non-Profit Organisation</label>
          <select
            className='_input-element'
            name="non_profit_organization" 
            value={inputs.non_profit_organization || ""} 
            onChange={handleChange}
            required
          >
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
      </div>

      <div className='_input-row'>

        <div className='_input-group'>
          <label>VAT Registered</label>
          <select
            className='_input-element'
            name="vat_registered" 
            value={inputs.vat_registered || ""} 
            onChange={handleChange}
            required
          >
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>

        <div className='_input-group'>
          <label>Industry Category</label>
          <select
            className='_input-element'
            name="industry_category" 
            value={inputs.industry_category || ""} 
            onChange={handleChange}
            required
          >
            <option value="">Please select an option</option>
            <option>Healthcare</option>
            <option>Welfare and Humanitarian</option>
            <option>Land and Housing</option>
            <option>Educational and Development</option>
            <option>Conservation, Environment and Animal Welfare</option>
            <option>None of the above</option>
          </select>
        </div>
      </div>

      <div className='_input-group' style={{textAlign: 'center', paddingBottom: '0'}}>
        <br />
        <button type="submit" className='_button'>Submit Request</button>
      </div>
      {errors && (
        <div className='_errors'>
          <Errors />
        </div>
      )}
    </form>
  )
}

export default ContactForm