import React from 'react'
import Layout from './Layout'
import TopBannerSection from './TopBannerSection'
import SecondSection from './SecondSection'
import FourthSection from './FourthSection'
import ThirdSection from './ThirdSection'
// import ComingSoonSection from './ComingSoonSection'
import GetInTouch from './GetInTouch'
import ContactDetails from './ContactDetails'
import overlayTop from '../assets/img/icon-cut-off-01.png'
import overlayBottom from '../assets/img/icon-cut-off-03.png'

class Home extends React.Component {
  render(){
    return(
      <Layout>
        <TopBannerSection />
        <div className='_overlay-1 _tablet'>
          <img src={overlayTop} alt="" />
        </div>
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <div className='_overlay-2 _tablet'>
          <img src={overlayBottom} alt="" />
        </div>
        {/* <ComingSoonSection /> */}
        <GetInTouch />
        <ContactDetails />
      </Layout>
    )
  }
}

export default Home