import React from 'react'
import ourVision from '../assets/img/our-vision.svg'
import ourTeam from '../assets/img/our-team.svg'
import ourPurpose from '../assets/img/our-purpose.svg'
import iconCutOff from '../assets/img/icon-cut-off-02.svg'

class FourthSection extends React.Component {
  render(){
    return(        
      <section className='_section'>
        <div className='_grid'>
          <div className='_column _padding-bottom-100'>
            <div className='_panel'>
              <h2 className='_heading-1'>OUR FEES</h2>
              <p>Our fees are simple, <strong>no saving no fee</strong>. VAT risk reviews are performed at no cost and our VAT compliance services are charged at a fixed cost per submission.</p>
              <p>We pride ourselves on providing personalised, efficient and cost-effective services to all our clients. Contact us today to learn more about how we can help your organisation to achieve its optimal VAT efficiency.</p>
              <a href="#contact" className='_button'>Set up a FREE consultation</a><br /><br />
            </div>
          </div>
        </div>
        <div className='_grid _grid-3 _our-fees'>
          <div className='_column '>
            <img className='_icon-cut-off' src={iconCutOff} alt="" />
            <img src={ourVision} alt="Our Vision" className='_icon' />  
            <h2 className='_heading-2'>OUR <span className='_orange'>VISION</span></h2>
            <p>is to be the leading VAT saving Specialists for South African companies.</p>
          </div>
          <div className='_column'>
            <img className='_icon-cut-off' src={iconCutOff} alt="" />
            <img src={ourTeam} alt="Our Team" className='_icon' />
            <h2 className='_heading-2'>OUR <span className='_orange'>TEAM</span></h2>
            <p>consists of experienced, professional and dedicated staff with an aim to exceed our clients’ expectations.</p>
          </div>
          <div className='_column'>
            <img className='_icon-cut-off' src={iconCutOff} alt="" />
            <img src={ourPurpose} alt="Our Purpose" className='_icon' />
            <h2 className='_heading-2'>OUR <span className='_orange'>PURPOSE</span></h2>
            <p>to utilise our expertise in identifying potential VAT saving opportunities for our clients.</p>
          </div>
        </div>
      </section>        
    )
  }
}

export default FourthSection