import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import './assets/scss/app.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);